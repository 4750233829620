import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, graphql, StaticQuery } from 'gatsby';
import Layout from '../../components/Layout';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    min-height: 75vh;
    box-sizing: border-box;
    padding: 3em 1em;
`;

const Header = styled.h2`
    width: 100%;
    text-align: center;
    margin-bottom: 2em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;

    @media only screen and (max-width: 992px) {
        width: 90%;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        margin: 0 1em;
        justify-content: center;
    }
`;

const Card = styled.div`
    background-image: url(${props => props.img || 'none'});
    background-size: cover;
    text-decoration: none;
    height: 15em;
    width: 100%;
    display: block;

    @media only screen and (max-width: 768px) {
        height: calc(90vw - 3em);
        width: 100%;
    }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const CardWrapper = styled(props => <Link {...props} />)`
    width: 20em;
    height: 18em;
    margin-bottom: 3em;
    display: flex;
    flex-wrap: wrap;
    align-items: space-between;
    text-decoration: none;

    @media only screen and (max-width: 768px) {
        width: 90vw;
        height: 90vw;
    }
`;

const Title = styled.span`
    top: -2em;
    left: 1em;
    font-size: 1em;
    font-weight: bold;
    color: black;
    height: 3em;
    width: 100%;
`;

const BlogIndexPage = props => {
    const { data } = props;
    const { edges: posts } = data.allMarkdownRemark;

    const cards = posts.map(
        (
            {
                node: {
                    frontmatter: { featuredimage, title },
                    fields: { slug },
                },
            },
            index
        ) => {
            const img =
                featuredimage && featuredimage.childImageSharp.fluid.src;
            return (
                <CardWrapper to={`/${slug}`}>
                    <Title>
                        #{posts.length - index} {title}
                    </Title>
                    <Card img={img} />
                </CardWrapper>
            );
        }
    );

    return (
        <Layout>
            <Container>
                <Header> REALIZACJE:</Header>
                <Wrapper>{cards}</Wrapper>
            </Container>
        </Layout>
    );
};

BlogIndexPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
};

export default () => (
    <StaticQuery
        query={graphql`
            query BlogAllPostsQuery {
                allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: { templateKey: { eq: "blog-post" } }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 400)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                templateKey
                                date(formatString: "MMMM DD, YYYY")
                                featuredpost
                                featuredimage {
                                    childImageSharp {
                                        fluid(maxWidth: 1200, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data, count) => <BlogIndexPage count={count} data={data} />}
    />
);
